<template>
    <b-card>
        <template v-slot:header>
            <h5>Form Edit Cabang</h5>
        </template>
        <div class="pb-3 mb-3 border-bottom row justify-content-end mx-1">
            <div class="col-md-4">
                <multiselect :searchable="true" @select="handleSelectCabang" v-model="currentCabang" :options="allCabang"
                    :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                    placeholder="Cari Cabang" label="name" track-by="id" :preselect-first="true">
                </multiselect>
            </div>
        </div>
        <b-form @submit.prevent="handleSubmit">
            <b-row>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Kode Cabang" label-for="kode-cabang" class="required">
                        <b-form-input id="kode-cabang" type="text" v-model.trim="newForm.kode"
                            placeholder="kode cabang"></b-form-input>
                        <span v-if="!$v.newForm.kode.required && $v.newForm.kode.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Nama Cabang" label-for="nama-cabang" class="required">
                        <b-form-input id="nama-cabang" type="text" v-model.trim="newForm.name"
                            placeholder="Nama cabang"></b-form-input>
                        <span v-if="!$v.newForm.name.required && $v.newForm.name.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Status Cabang" label-for="status-cabang" class="required">
                        <b-form-select v-model="newForm.is_active" :options="optionsStatus"></b-form-select>
                        <span v-if="!$v.newForm.is_active.required && $v.newForm.is_active.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Tlp Cabang" label-for="tlp-cabang" class="required">
                        <b-form-input id="tlp-cabang" type="number" v-model.trim="newForm.telepon"
                            placeholder="Tlp cabang"></b-form-input>
                        <span v-if="!$v.newForm.telepon.required && $v.newForm.telepon.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Hp Cabang" label-for="hp-cabang" class="required">
                        <b-form-input id="hp-cabang" type="number" v-model.trim="newForm.hp"
                            placeholder="Hp Cabang"></b-form-input>
                        <span v-if="!$v.newForm.hp.required && $v.newForm.hp.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group id="fg-create-kepala-unit-cabang" label="Kepala Unit"
                        label-for="input-kepala-unit-cabang">
                        <!-- <multiselect :searchable="true" @search-change="searchUser" v-model="newForm.kepala_unit"
                            :options="resultUsers" :multiple="false" :close-on-select="true" :clear-on-select="false"
                            :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name" track-by="id"
                            :preselect-first="true">
                        </multiselect> -->
                        <multiselect :searchable="true" @search-change="searchUser" v-model="newForm.kepala_unit"
                            :options="defaultku" :multiple="false" :close-on-select="true" :clear-on-select="false"
                            :preserve-search="true" placeholder="Cari Kepala Unit" label="name" track-by="id"
                            :preselect-first="true">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group id="fg-create-kepala-cabang" label="Kepala Cabang" label-for="input-kepala-cabang">
                        <!-- <multiselect :searchable="true" @search-change="searchUser" v-model="newForm.kepala_cabang"
                            :options="resultUsers" :multiple="false" :close-on-select="true" :clear-on-select="false"
                            :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name" track-by="id"
                            :preselect-first="true">
                        </multiselect> -->
                        <multiselect :searchable="true" @search-change="searchUser" v-model="newForm.kepala_cabang"
                            :options="defaultkc" :multiple="false" :close-on-select="true" :clear-on-select="false"
                            :preserve-search="true" placeholder="Cari Kepala Cabang" label="name" track-by="id"
                            :preselect-first="true">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group id="area_manager_id" label="Area Manager" label-for="area_manager_id">
                        <multiselect :searchable="true" v-model="newForm.area_manager" :options="defaultam"
                            :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                            placeholder="Cari Area Manager" label="name" track-by="id" :preselect-first="true">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="6" lg="4" xl="3">
                    <b-form-group label="Kabupaten/Kota" label-for="kabupaten" class="required">
                        <multiselect v-model="newForm.kabupaten_kota" :options="kabupatens" :multiple="false"
                            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Kabupaten"
                            label="name" track-by="id" :preselect-first="true">
                        </multiselect>
                        <span v-if="!$v.newForm.kabupaten_kota.required && $v.newForm.kabupaten_kota.$dirty"
                            class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group id="alamat-cabang" label="Alamat Cabang" label-for="alamat-cabang" class="required">
                        <b-form-textarea id="alamat-cabang" type="text" rows="4" v-model.trim="newForm.alamat"
                            placeholder="Masukkan alamat cabang"></b-form-textarea>
                        <span v-if="!$v.newForm.alamat.required && $v.newForm.alamat.$dirty" class="text-danger">
                            Kolom tidak boleh kosong
                        </span>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-between mt-0 mt-md-4">
                <b-button class="mr-2" variant="dark" @click="handleBack">Kembali</b-button>
                <b-button variant="pgiBtn" type="submit">Simpan Perubahan</b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";
import axiosClient from '../../../config/api';
export default {
    mixins: [validationMixin],
    data() {
        return {
            currentCabang: null,
            allCabang: [],
            optionsStatus: [
                { value: null, disabled: true, text: 'Status' },
                { value: 1, text: 'Aktif' },
                { value: 0, text: 'Non Aktif' },
            ],
            defaultkc: [],
            defaultku: [],
            defaultam: [],
            newForm: {
                id: null,
                kode: null,
                name: null,
                hp: null,
                telepon: null,
                alamat: null,
                kabupaten_kota: null,
                is_active: 1,
                kepala_cabang: null,
                kepala_unit: null,
                area_manager: null
            }
        }
    },
    validations: {
        newForm: {
            kode: {
                required,
            },
            name: {
                required,
            },
            telepon: {
                required,
            },
            hp: {
                required,
            },
            alamat: {
                required,
            },
            kabupaten_kota: {
                required,
            },
            is_active: {
                required,
            },
        },
    },

    methods: {
        // ======== //
        async detailCabang() {
            try {
                const response = await axiosClient({
                    method: 'GET',
                    url: `admin/cabang/${this.id}`
                })
                const data = response.data.data;
                this.user_cabang = data.user_cabang;
                this.kepala_unit = data.kepala_unit
                this.kepala_cabang = data.kepala_cabang
                this.kepala_cabang_senior = data.kepala_cabang_senior
                this.area_manager = data.area_manager
                this.form = {
                    ...this.form,
                    ...data
                }
                this.currentCabang = {
                    id: data.id,
                    name: data.name,
                }
                this.newForm = {
                    id: data.id,
                    kode: data.kode,
                    name: data.name,
                    hp: data.hp,
                    telepon: data.telepon,
                    alamat: data.alamat,
                    kabupaten_kota: data.kabupaten_kota,
                    is_active: data.is_active,
                    kepala_cabang: data.kepala_cabang,
                    kepala_unit: data.kepala_unit,
                    area_manager: data.area_manager
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getAllCabang() {
            try {
                const { data } = await axiosClient({
                    method: 'GET',
                    url: `admin/cabang/all`
                })
                const d = data.data
                this.allCabang = d;

            } catch (error) {
                console.log(error)
            }
        },
        async handleSelectCabang(cabang) {
            const cabangId = cabang.id
            this.$router.push(`/master-cabang/cabang-edit?id=${cabangId}`)
            this.detailCabang()
        },
        async getDefaultKc() {
            try {
                const { data } = await axiosClient({
                    method: 'GET',
                    url: `admin/cabang-user/user-kc`
                })
                const d = data.data
                this.defaultkc = d;

            } catch (error) {
                console.log(error)
            }
        },
        async getDefaultKu() {
            try {
                const { data } = await axiosClient({
                    method: 'GET',
                    url: `admin/cabang-user/user-ku`
                })
                const d = data.data
                this.defaultku = d;

            } catch (error) {
                console.log(error)
            }
        },
        async getDefaultAm() {
            try {
                const { data } = await axiosClient({
                    method: 'GET',
                    url: `admin/cabang-user/user-am`
                })
                const d = data.data
                this.defaultam = d;

            } catch (error) {
                console.log(error)
            }
        },
        searchUser(value) {
            if (value.length > 2) {
                this.$store.dispatch("searchUser", { search: value })
            }
        },
        // ======== //
        async handleSubmit() {
            this.$v.newForm.$touch();
            if (this.$v.newForm.$anyError) {
                return;
            } else {
                const newFormFixed = {
                    is_active: this.newForm.is_active,
                    kode: this.newForm.kode,
                    name: this.newForm.name,
                    alamat: this.newForm.alamat,
                    hp: this.newForm.hp,
                    telepon: this.newForm.telepon,
                    area_manager_id: this.newForm.area_manager ? this.newForm.area_manager.id : undefined,
                    kepala_unit_id: this.newForm.kepala_unit ? this.newForm.kepala_unit.id : undefined,
                    kepala_cabang_id: this.newForm.kepala_cabang ? this.newForm.kepala_cabang.id : undefined,
                    kabupaten_kota_id: this.newForm.kabupaten_kota ? this.newForm.kabupaten_kota.id : undefined,
                }
                this.showSweetAlertEdit(newFormFixed)
            }
        },
        showSweetAlertEdit: function (newFormFixed) {
            var content = document.createElement("div");
            content.innerHTML =
                "<p style='text-align: left;'>Apakah anda yakin melakukan perubahan pada data berikut: \n</p>" +
                "<div style='width: 100%; text-align: left; margin-top: 10px'>" +
                "<table class='small'>" +
                "<tr>" +
                "<td>Divisi</td>" +
                "<td style='width: 5%'>:</td>" +
                "<td>" +
                "<strong>" +
                newFormFixed.name +
                "</strong>" +
                "</td>" +
                "</tr>" +
                "</table>" +
                "</div>";

            this.$swal({
                title: "Konfirmasi",
                html: content,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Konfirmasi",
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.isSubmitting = true;
                    this.$axios
                        .post("/api/admin/cabang/" + this.id, newFormFixed)
                        .then((response) => {
                            if (response.status == 200) {
                                // console.log("TEST")
                                this.$helper.toastSucc(this, "Proses data sukses")
                            } else {
                                this.$helper.toastErr(this, "Proses data error")
                            }
                        })
                        .catch(() => {
                            this.$helper.toastErr(this, "Proses data error")
                        })
                        .finally(() => (this.isSubmitting = false));
                }
            });
        },
        handleBack() {
            this.$router.push('/master-cabang/cabang')
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        kabupatens() {
            return this.$store.getters.kabupatenLists;
        },
        resultUsers() {
            return this.$store.getters.resultUsers;
        },
    },
    created: function () {
        this.detailCabang()
        this.getDefaultKc()
        this.getDefaultKu()
        this.getAllCabang()
        this.getDefaultAm()
        this.$store.dispatch("getKabupaten")
    },
}
</script>